<template>
  <transition name="fade">
    <div
      class="basket-model"
      v-show="show_basket_model"
      @click.self="toggle_basket_model"
    >
      <div class="basket-model-page">
        <div class="basket-model-container">
          <div
            class="basket-model-wrapper"
            @focus="handleFocus"
            @focusout="handleFocusOut"
            tabindex="0"
          >
            <BasketItem
              v-if="show_basket_items"
              v-on:finaltotalvalueChange="finaltotalvalueChange"
              v-on:basketItemsNumber="basketItemsNumber"
              :prop_parking_cartitem="cartitem"
              :item_price="cartitem ? cartitem.price : undefined"
              :item_title="cartitem ? cartitem.carpark_name : undefined"
              :item_start_date="
                cartitem ? formatDatedmy(cartitem.valid_from) : undefined
              "
              :item_start_time="cartitem ? cartitem.start_time : undefined"
              :item_end_date="
                cartitem ? formatDatedmy(cartitem.valid_till) : undefined
              "
              :item_end_time="cartitem ? cartitem.end_time : undefined"
              :prop_fasttrack_cartitem="fasttrack_cartitem"
              :remove_fasttrack_cartitem="update_fasttrack_cartitem"
              :prop_lounge_cartitem="lounge_cartitem"
              :remove_lounge_cartitem="update_lounge_cartitem"
              :remove_coach_cartitem="update_coach_cartitem"
              :prop_coach_cartitem="coach_cartitem"
              :update_cart_items="update_cart_items"
              :lang="lang.toUpperCase()"
              :translations="translations"
              :brandSlug="brandSlug"
              :is_model="true"
              :is_model_subtotal="finaprice"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BasketItem from "./../components/BasketItem.vue";

const { EncryptStorage } = require("encrypt-storage");
const encryptStorage = new EncryptStorage(process.env.VUE_APP_CHL, {
  storageType: "sessionStorage",
});

export default {
  name: "BasketModel",
  components: {
    BasketItem,
  },
  props: {
    brandSlug: undefined,
    translations: undefined,
    lang: undefined,
  },
  data() {
    return {
      show_basket_items: true,
      show_basket_model: false,
      cartitem: {},
      finaprice: 0,
      cartitem_park: {},
      fasttrack_cartitem: {},
      lounge_cartitem: {},
      coach_cartitem: {},
    };
  },
  methods: {
    gotoPage(url) {
      window.location.href = url;
    },
    formatDatedmy(date) {
      if (typeof date !== "undefined") {
        date = date.split("/");
        date = date[2] + "/" + date[1] + "/" + date[0];
        return date;
      } else {
        return "";
      }
    },
    toggle_basket_model() {
      const new_event = new CustomEvent("basket_model_toggle", {
        detail: {
          data: "toggle",
        },
      });
      window.dispatchEvent(new_event);
    },
    update_fasttrack_cartitem() {
      this.show_basket_items = false;
      this.toggle_basket_model();
      this.fasttrack_cartitem = encryptStorage.getItem("fasttrack_cart");
      window.location.reload();
    },
    update_lounge_cartitem() {
      this.show_basket_items = false;
      this.toggle_basket_model();
      this.lounge_cartitem = encryptStorage.getItem("lounge_cart");
      window.location.reload();
    },
    update_coach_cartitem() {
      this.show_basket_items = false;
      this.toggle_basket_model();      
      this.coach_cartitem = encryptStorage.getItem("coach_cart");
      window.location.reload();
    },
    update_cart_items() {
      this.show_basket_items = false;
      this.toggle_basket_model();
      this.cartitem = encryptStorage.getItem("cart");
      window.location.reload();
    },
    showtotalvaluecomputed() {
      console.log("is_model_subtotal", "aaaaaaaaaaaaaaaa");
      let totalvalue = 0;
      if (typeof encryptStorage.getItem("fasttrack_cart") !== "undefined") {
        totalvalue =
          totalvalue +
          parseFloat(
            encryptStorage.getItem("fasttrack_cart").fasttracksubtotal
          );
        this.finaltotalvalue_ftt =
          parseFloat(
            encryptStorage.getItem("fasttrack_cart").fasttracksubtotal
          ) - parseFloat(encryptStorage.getItem("dis_ftt"));
      }

      if (typeof encryptStorage.getItem("lounge_cart") !== "undefined") {
        totalvalue =
          totalvalue +
          parseFloat(encryptStorage.getItem("lounge_cart").loungesubtotal);
        this.finaltotalvalue_lng =
          parseFloat(encryptStorage.getItem("lounge_cart").loungesubtotal) -
          parseFloat(encryptStorage.getItem("dis_lng"));
      }

      if (typeof encryptStorage.getItem("cart") !== "undefined") {
        totalvalue =
          totalvalue + parseFloat(encryptStorage.getItem("cart").price);
        this.finaltotalvalue_cpk =
          parseFloat(encryptStorage.getItem("cart").price) -
          parseFloat(encryptStorage.getItem("dis_cpk"));
      }
      console.log("totalfeeaaa", totalvalue);
      let discount_amount = encryptStorage.getItem("discount_amount");
      //let setcurrency = "1-GBP";
      if (typeof discount_amount === "undefined" || discount_amount === null) {
        discount_amount = 0;
      }
      console.log("discount_amount", discount_amount);
      discount_amount = parseFloat(discount_amount);
      let totalfee = parseFloat(totalvalue);
      totalfee = totalfee - discount_amount;
      console.log("totalfee", totalfee);
      console.log("discount_amount", discount_amount);
      totalfee = totalfee.toFixed(2);

      this.finaprice = totalfee;
      console.log("finaprice", this.finaprice);
    },
  },

  mounted() {
    this.cartitem = encryptStorage.getItem("cart");
    this.cartitem_park = encryptStorage.getItem("cart");
    this.fasttrack_cartitem = encryptStorage.getItem("fasttrack_cart");
    this.lounge_cartitem = encryptStorage.getItem("lounge_cart");
    this.coach_cartitem = encryptStorage.getItem("coach_cart");
    this.showtotalvaluecomputed();
    window.addEventListener(
      "basket_model_toggle",
      () => {
        this.show_basket_model = !this.show_basket_model;
      },
      false
    );

    window.addEventListener(
      "update_basket",
      () => {
        this.cartitem = encryptStorage.getItem("cart");
        this.cartitem_park = encryptStorage.getItem("cart");
        this.fasttrack_cartitem = encryptStorage.getItem("fasttrack_cart");
        this.lounge_cartitem = encryptStorage.getItem("lounge_cart");
        this.coach_cartitem = encryptStorage.getItem("coach_cart");
        this.showtotalvaluecomputed();
      },
      false
    );
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/variables.scss";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.basket-model {
  position: absolute; // main type
  top: 0;
  padding-top: calc(var(--header_height_desktop, 100px) + 1rem);
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9999990;
  pointer-events: all;
  overflow-y: auto;
  overflow-x: hidden;

  .basket-model-page {
    max-width: 1260px;
    width: 100%;
    position: relative;
    pointer-events: none;
    margin: 0 1rem;

    .basket-model-container {
      position: absolute;
      height: auto;
      width: 100%;
      max-width: 430px;

      @media screen and (max-width: 768.998px) {
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        bottom: 0;
      }

      @media screen and (min-width: 768px) {
        right: 0;
        top: 0;
        bottom: 0;
      }

      .basket-model-wrapper {
        pointer-events: all;
        height: auto;
        background: #fff;
        border-radius: 0.25rem;
        border: 1px solid #ddd;
        box-shadow: 0px 3px 6px #00000029;
      }
    }
  }
}
</style>
